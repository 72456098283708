
import { defineComponent } from 'vue'
import Pic from '@/components/Pic.vue'
import RedPointAlert from '@/provider/RedPointAlert.vue'

export default defineComponent({
  name: 'TheActivity',
  components: { Pic, RedPointAlert },
  setup () {
    return {
      menus: [
        {
          route: '/membership/store',
          icon: 'user/Points_store',
          mainLabel: 'me_18',
          subLabel: 'me_19',
          title: 'Points store',
          subTitle: 'Exchange points for coupons and iphone',
        },
        {
          route: '/user/leverage',
          icon: 'user/leverage',
          mainLabel: 'me_20',
          subLabel: 'me_21',
          title: 'Leverage adjustment',
          subTitle: 'Choose what’s best for you',
          redPointId: 't_l_a',
        },
        {
          route: '/user/interest',
          icon: 'user/Interest_on_balance',
          mainLabel: 'me_14',
          subLabel: 'me_16',
          title: 'Interest on balance',
          subTitle: 'Up to 6% / YR',
          redPointId: 't_i_o_b',
        },
        {
          route: '/share/invite',
          icon: 'user/Invite_friends',
          mainLabel: 'me_13',
          subLabel: 'me_15',
          title: 'Invite friends',
          subTitle: 'Get $5 coupon',
        },
      ],
    }
  },
})
