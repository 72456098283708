
import { callService, checkCSMsgState } from '@/common/jsBridge.api'
import Pic from '@/components/Pic.vue'
import { defineComponent, shallowRef } from 'vue'

export default defineComponent({
  name: 'TheContactButton',
  components: { Pic },
  setup () {
    const unread = shallowRef(false)

    checkCSMsgState().then(resp => {
      unread.value = (resp as { hasNewMsg: boolean }).hasNewMsg
    })

    return {
      unread,
      callService,
    }
  },
})
