
import Icon from '@/components/Icon.vue'
import Pic from '@/components/Pic.vue'
import { keymap } from '@/config'
import TheContactButton from '@/modules/user/components/TheContactButton.vue'
import Page from '@/provider/Page.vue'
import { localGet } from 'essential/store/localStore'
import { computed, defineComponent, shallowRef, watch } from 'vue'
import { isLogged } from '@/state'
import TheActivity from '@/modules/user/components/TheActivity.vue'
import RedPointAlert from '@/provider/RedPointAlert.vue'

export default defineComponent({
  name: 'Me',
  components: { TheActivity, TheContactButton, Icon, Pic, Page, RedPointAlert },
  setup () {
    const isLogin = computed(isLogged)
    const avatar = shallowRef(localGet(keymap.user.avatar))
    const nickName = shallowRef(localGet(keymap.user.nickName))

    watch(isLogin, () => {
      avatar.value = localGet(keymap.user.avatar)
      nickName.value = localGet(keymap.user.nickName)
    })

    return {
      menus: [
        {
          route: '/me/history',
          icon: 'user/history',
          label: 'me_9',
          labelDefault: 'Trade History',
        },
        // {
        //   route: '/me/io',
        //   icon: 'user/history_t',
        //   label: 'me_10',
        //   labelDefault: 'Transaction History',
        // },
        // {
        //   route: '/setting/locale',
        //   icon: 'user/locale',
        //   label: 'me_11',
        //   labelDefault: 'Language',
        // },
        // {
        //   route: '/user/aboutus',
        //   icon: '',
        //   label: 'me_22',
        //   labelDefault: 'About WeTrade',
        //   redPoint: 'm_a_u',
        // },
      ],
      avatar,
      nickName,
      isLogin: computed(isLogged),
    }
  },
})
